import React from "react"
import { PageTitle } from "../parts/Title"

export function PaymentPolicy() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <PageTitle>Chính sách thanh toán</PageTitle>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <p className="leading-relaxed text-base">
                cokhach.vn cung cấp các gói dịch vụ dựa theo giới hạn tính năng
                và quy mô đội nhóm để Khách hàng có thể đăng ký mua và sử dụng
                các tính năng nâng cao của hệ thống.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                1. Quy trình và hình thức thanh toán
              </h2>
              <p className="leading-relaxed text-base">
                Ở thời điểm hiện tại, Khách hàng có thể đăng ký mua và sử dụng
                dịch vụ của cokhach.vn bằng hình thức chuyển khoản tới tài khoản
                ngân hàng của Công ty TNHH Game Changer - đơn vị chủ quản, phát
                triển và vận hành nền tảng cokhach.vn.
                <br />
                Phí dịch vụ cokhach.vn được tính và thanh toán theo tháng.{" "}
                <br />
                Khách hàng thao tác lựa chọn gói dịch vụ tương ứng với nhu cầu
                sử dụng cho không gian làm việc (Workspace) của cá nhân (Home)
                hoặc đội nhóm, sau đó tiếp tục với các bước thanh toán và thực
                hiện chuyển khoản chính xác số tiền và nội dung chuyển khoản
                được cung cấp.
                <br />
                Nếu mọi thông tin chuyển khoản chính xác, giao dịch của Khách
                hàng sẽ được tự động ghi nhận và tài khoản cá nhân hoặc đội nhóm
                tương ứng của Khách hàng được tự động nâng cấp, theo đó Khách
                hàng và tất cả những người dùng trong không gian làm việc tương
                ứng sẽ được hưởng toàn bộ quyền lợi của gói dịch vụ đã trả phí.
                <br />
                Trong trường hợp thông tin chuyển khoản chưa chính xác, vui lòng
                liên hệ hotline hỗ trợ thanh toán của chúng tôi: 0904220612 để
                được xử lý và nâng cấp tài khoản.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                2. Quản lý thông tin thanh toán
              </h2>
              <p className="leading-relaxed text-base">
                Khách hàng có thể đăng nhập vào tài khoản cokhach.vn và truy cập
                Xem thông tin gói để xem chi tiết các gói nâng cấp cho một hoặc
                nhiều nhóm cùng với lịch sử giao dịch của tài khoản. Khi tới kỳ
                hạn trả phí để duy trì gói, cokhach.vn sẽ thông báo tới Khách
                hàng qua email trước tối thiểu 3 ngày.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                3. Không nhận được thanh toán
              </h2>
              <p className="leading-relaxed text-base">
                cokhach.vn có quyền tạm ngừng việc sử dụng của Khách hàng nếu
                Khách hàng không thực hiện nghĩa vụ thanh toán các khoản chi phí
                sử dụng dịch vụ sau khi cokhach.vn đã gửi thông báo yêu cầu
                thanh toán 15 ngày.
                <br />
                Trong trường hợp không trả phí đúng hạn, Landing page mà Khách
                hàng đã xuất bản sẽ vẫn hoạt động và khách thăm vẫn có thể truy
                cập trang, tuy nhiên Khách hàng sẽ không thể sử dụng công cụ để
                chỉnh sửa trang này hoặc tạo trang mới. Dữ liệu Landing page của
                Khách hàng (bao gồm ảnh và nội dung) vẫn được lưu trong hệ
                thống, back-up trong vòng 12 tháng, bất cứ lúc nào Khách hàng
                gia hạn trở lại, các thông tin cũ của Landing Page vẫn được giữ
                nguyên.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                4. Đổi/ trả dịch vụ và hoàn tiền
              </h2>
              <p className="leading-relaxed text-base">
                Trong hầu hết các trường hợp, cokhach.vn không hỗ trợ hoàn trả
                phí dịch vụ, cũng như không có điều khoản hoàn tiền cho những
                dịch vụ đã được sử dụng một phần. cokhach.vn cung cấp gói dùng
                thử miễn phí, và khuyến khích Khách hàng sử dụng để có thể trải
                nghiệm trước khi mua.
                <br />
                Trong một số trường hợp ngoại lệ (lỗi giao dịch, giao dịch trùng
                lặp do lỗi…), cokhach.vn có thể chấp nhận hoàn tiền nếu Khách
                hàng gửi yêu cầu hoàn tiền trong vòng 03 ngày kể từ ngày giao
                dịch và cung cấp được các bằng chứng giao dịch xác đáng.
                <br />
                Khách hàng có thể thay đổi lựa chọn gói dịch vụ bằng cách thao
                tác trực tiếp trên trang Bảng giá và lựa chọn gói dịch vụ mới để
                chuyển đổi & thanh toán các khoản phí chênh lệch. Khách hàng chỉ
                có thể chuyển đổi sang gói dịch vụ khác với quy mô nhóm lớn hơn
                hoặc bằng số lượng người trong nhóm hiện tại.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
