import React, { ReactElement } from "react"
import { Header } from "../components/Header"
import { PaymentPolicy } from "../components/policy/PaymentPolicy"
import { Footer } from "../components/Footer"
import { Meta } from "../components/Meta"

interface Props {}

function PolicyPayment(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Chính sách | cokhach.vn" />
      <Header />
      <PaymentPolicy />
      <Footer />
    </>
  )
}

export default PolicyPayment
